<template>
    <v-container fluid>
        <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
          ></v-progress-linear>

        <v-row justify="center" v-if="!loading">
            <v-col cols="12" md="8">
                <v-container fluid class="mt-12 py-12">
                    <v-row>
                        <v-col cols="12" md="8">
                            <v-img :src="main_logo" max-width="35%"/>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-select @change="languageChoosed" v-model="$i18n.locale" :items="languages" item-text="language" :label="$t('globals.choose_language')">
                                <template v-slot:selection="{  }">
                                    <flag class="mr-4" :iso="$i18n.locale == 'en' ? 'gb': $i18n.locale" /> {{getExtension()}}
                                </template>
                                <template v-slot:item="{ item }">
                                    <flag class="mr-4" :iso="item.language == 'en' ? 'gb' : item.language" />{{item.extension}}
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card class="elevation-8">
                    <v-card-title class="primary white--text text-h2">
                        <v-row>
                            <v-col cols="12" md="8">
                                {{fields.i18n[$i18n.locale].title}}
                            </v-col>
                            <v-col cols="12" md="4" class="text-caption d-flex align-center justify-end">
                                <v-btn @click="dialogHowTo = true;" outlined dark class="white--text">
                                    <v-icon small class="mr-2">mdi-help</v-icon> Tutorial
                                </v-btn>
                            </v-col>
                        </v-row>                        
                    </v-card-title>
                    <v-card-text class="mt-4" v-html="fields.i18n[$i18n.locale].description">
                        
                    </v-card-text>
                    <v-card-actions class="d-none d-lg-block">
                        <v-btn @click="$router.push($i18n.locale + '/complaint')">{{$t('globals.create_complaint')}}</v-btn>
                        <v-btn outlined @click="checkComplaint = true">{{$t('globals.check_complaint')}}</v-btn>
                    </v-card-actions>
                    <v-card-actions class="d-sm-none">
                        <v-row>
                            <v-col cols="12">
                                <v-btn block @click="$router.push($i18n.locale + '/complaint')">{{$t('globals.create_complaint')}}</v-btn>
                            </v-col>
                            <v-col cols="12">
                                <v-btn block outlined @click="checkComplaint = true">{{$t('globals.check_complaint')}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>

            </v-col>
        </v-row>

        <v-dialog
            v-model="checkComplaint"
            width="600"
            persistent
            >

            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    {{$t('home.insert_pwd')}}
                </v-card-title>

                <v-card-text class="mt-4">
                    {{$t('home.given')}}
                </v-card-text>
                <v-card-text>
                    <v-text-field
                        v-model="password"
                        prepend-inner-icon="mdi-account-details"
                        label="Password *"
                        clearable
                    ></v-text-field>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-btn
                    color="error"
                    @click="checkComplaint = false;"
                >
                    {{$t('globals.close')}}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    @click="checkComplaintFunc"
                >
                    {{$t('globals.check')}}
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
            
    
            <v-dialog
                v-model="dialogHowTo"
                width="50%"
            >
                <v-card>
                    <v-card-title class="primary white--text">
                        Tutorial
                        <v-spacer></v-spacer>
                        <v-btn
                            class="white--text"
                            right
                            text
                            @click="dialogHowTo = false"
                            small
                        >
                            X
                        </v-btn>
                    </v-card-title>

                    <v-card-text>
                        <v-row>
                            <v-col cols="12">                                
                                <div style="position: relative; box-sizing: content-box; max-height: 80vh; max-height: 80svh; width: 100%; aspect-ratio: 1.941908713692946; padding: 40px 0 40px 0;">
                                    <iframe src="https://app.supademo.com/embed/cm16kwf7q0l0qme0p514p8x8u?embed_v=2" loading="lazy" title="Ignoto_criar denuncia" allow="clipboard-write" frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
    </v-container>
</template>
<script>
import Complaints from '@/api/Complaints.js'
import Languages from '@/api/Languages.js'
import Branding from '@/api/Branding.js'

export default{
    name: "Homepage",
    data(){
        return{
            loading: true,
            dialogHowTo: false,
            fields:{},
            languages: [],
            checkComplaint: false,
            password: null,
            main_logo: '@/assets/logo_dream.png'
        }
    },
    beforeMount(){
        Branding.get().then((data)  =>  {
            if(data.data.logo != null){
                this.main_logo = data.data.logo
            }
            if(data.data.theme != null){
                let theme = JSON.parse(data.data.theme.value)
                this.$vuetify.theme.themes.dark.primary = theme.primary.slice(0, -2)
                this.$vuetify.theme.themes.light.primary = theme.primary.slice(0, -2)
            }
        })
    },
    mounted(){
        this.loading = true;
        this.fillBaseData()
    },
    methods:{
        getExtension(){
            let returner = 'Português';
            this.languages.forEach(element => {
                
                if(element.language == this.$i18n.locale)
                    returner = element.extension
            });

            return returner
        },
        checkComplaintFunc(){
            if(this.password == null)
                return

            this.checkComplaint = false; 
            this.$router.push(this.$i18n.locale + '/complaint/' + this.password)
        },
        languageChoosed(l){
            this.$i18n.locale = l
            this.$route.params.lang = l
            this.$router.push({name: this.$router.currentRoute.name, params: this.$route.params})
        },
        async fillBaseData(){
            await Complaints.getIntroduction().then(({data}) => {
                this.fields = data
            });
            
            await Languages.list().then(({data}) => {
                this.languages = data
            });

            this.loading = false
        }
    }
}
</script>