<template>
    <v-card class="elevation-0 ma-0 pa-0">
      <v-card-title class="primary white--text text-h3 font-weight-bold">
        {{ method == 'create' ? $t('globals.create_complaint') : $t('globals.see_complaint')}}
      </v-card-title>
      <v-card-text class="mt-4">
        <validation-observer ref="form" v-slot="{ invalid }">
          <v-form @submit.prevent="submit"  class="mb-16">
            <v-progress-linear v-if="loading"
                indeterminate
                class="global-loader"
              ></v-progress-linear>
            <fieldset :disabled="loading">          
              <v-row>
                <v-col cols="12" md="12">
                  <validation-provider v-slot="{ errors }" :vid="$t('globals.title')" name="Título" rules="required">
                    <v-text-field
                      v-model="fields.title"
                      filled rounded
                      :label="$t('globals.title') + '*'"
                      clearable
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" :vid="$t('globals.category')" name="Categoria" rules="required">
                    <v-select
                      v-model="fields.category_id"
                      filled rounded
                      :items="categories"
                      prepend-inner-icon="mdi-account-details"
                      :label="$t('globals.category') + '*'"
                      :item-text="item => item.i18n[$i18n.locale]"
                      :item-value="item => item.id"
                      clearable
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" :vid="$t('globals.department')" name="Departamento" rules="required">
                    <v-select
                      v-model="fields.department_id"
                      filled rounded
                      :items="departments"
                      prepend-inner-icon="mdi-account-details"
                      :label="$t('globals.department') + '*'"
                      :item-text="item => item.i18n[$i18n.locale]"
                      :item-value="item => item.id"
                      clearable
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="12">
                  <validation-provider v-slot="{ errors }" :vid="$t('globals.complaint')" name="Descrição" rules="required">
                    <v-textarea
                      filled rounded
                      v-model="fields.description"
                      :label="$t('globals.complaint') + '*'"
                      clearable
                      :error-messages="errors"
                    ></v-textarea>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="12">
                  <validation-provider v-slot="{ errors }" :vid="$t('globals.confidential')" name="Descrição" rules="required">
                    <v-card class="elevation-2 ma-0 pa-0 rounded-lg" outlined>
                      <v-card-title>
                        <v-switch
                          inset
                          v-model="fields.confidential"
                          prepend-inner-icon="mdi-account-details"
                          :label="$t('globals.confidential')"
                          clearable
                          @change="changedSwitchesConfidential"
                          :error-messages="errors"
                        ></v-switch>
                      </v-card-title>
                      <v-card-text>
                        {{$t('complaints.confidencial')}}
                      </v-card-text>
                    </v-card>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="12">
                  <validation-provider v-slot="{ errors }" :vid="$t('globals.anonymous')" name="Descrição" rules="required">
                    <v-card class="elevation-2 ma-0 pa-0 rounded-lg" outlined>
                      <v-card-title>
                        <v-switch
                          inset
                          v-model="fields.anonymous"
                          prepend-inner-icon="mdi-account-details"
                          :label="$t('globals.anonymous')"
                          clearable
                          @change="changedSwitchesAnonymous"
                          :error-messages="errors"
                        ></v-switch>
                      </v-card-title>
                      <v-card-text>
                        {{$t('complaints.anonymous')}}
                      </v-card-text>
                    </v-card>
                  </validation-provider>
                </v-col>
                <v-col cols="12" v-if="fields.confidential">
                  <v-card class="elevation-2 ma-0 pa-0 rounded-lg" outlined>
                    <v-card-title class="primary white--text">
                      {{$t('globals.personal')}}
                    </v-card-title>
                    <v-card-text class="mt-4">                        
                      <v-row>
                        <v-col cols="12" md="12">
                          <validation-provider v-slot="{ errors }" :vid="$t('globals.name')" name="Nome" rules="|">
                            <v-text-field
                              filled rounded
                              v-model="fields.person.name"
                              :label="$t('globals.name')"
                              clearable
                              :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" md="6">
                          <validation-provider v-slot="{ errors }" :vid="$t('globals.email')" name="email" rules="required">
                            <v-text-field
                              filled rounded
                              v-model="fields.person.email"
                              :label="$t('globals.email') + '*'"
                              clearable
                              :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" md="6">
                          <validation-provider v-slot="{ errors }" :vid="$t('globals.contact')" name="Contact" rules="|">
                            <v-text-field
                              filled rounded
                              v-model="fields.person.telephone"
                              :label="$t('globals.contact')"
                              clearable
                              :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-card-text>                
                  </v-card>
            </v-col>
                <v-col cols="12">
                  <v-file-input
                    v-model="fields.files"
                    filled rounded
                    counter
                    multiple
                    :label="$t('globals.attach')"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  {{$t('globals.deactivate')}}
                </v-col>
                <v-col cols="12" md="4" v-for="user in users" :key="user.id">
                  <v-card 
                    shaped
                    outlined
                    :class="user.selected ? 'elevation-24 gradient' : 'elevation-0'"
                    >
                    <v-checkbox
                      v-model="user.selected"      
                    ></v-checkbox>
                    <v-card-title class="mt-n8">
                      <v-avatar
                          style="margin: auto;"
                          class="primary white--text text-h6"
                          size="72"
                        >
                            {{user.initials}}
                      </v-avatar>
                    </v-card-title>
                    <v-card-text class="text-center">
                      {{user.name}}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="mt-6" align="center" justify="space-around">
                <v-btn :disabled="invalid" depressed color="primary" type="submit">
                  {{$t('globals.save')}}
                </v-btn>
              </v-row>
            </fieldset>
          </v-form>
          
        </validation-observer>
        <v-dialog
          v-model="dialog"
          width="600"
          persistent
        >

          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              {{$t('globals.success')}}
            </v-card-title>

            <v-card-text class="mt-4">
              <v-row>
                <v-col cols="12" md="12">
                  <v-alert
                    outlined
                    dense
                    type="warning"
                    prominent
                    border="left"
                  >
                  Esta será a única vez que o nosso software disponibiliza a sua password
                  </v-alert>
                </v-col>
                <v-col cols="12">
                  Deve guardar a seguinte password. Guarde a mesma em segurança. Caso a perca não terá mais acesso a esta denúncia
                </v-col>
              </v-row>
              <v-row justify="start">
                <v-col cols="10">
                  <v-chip label>{{password}}</v-chip>
                </v-col>
                <v-col cols="2">              
                  <v-btn @click="copyToClipboard" icon>
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>            
                <v-col cols="12">              
                  <v-btn class="primary" dark outlined @click="downloadPassword">
                    <v-icon class="mr-2">mdi-download-box</v-icon> Download
                  </v-btn>
                </v-col>
              </v-row>
              
              
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-switch class="text-center" 
                        :label="$t('globals.secure_pwd')" 
                        v-model="safe_password" />
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="dialog = false; $router.push('complaint/success')"
                :disabled="safe_password == false"
              >
                {{$t('globals.go_ahead')}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <ErrorSnackbar v-model="error.show" :message="error.message"/>
      </v-card-text>
    </v-card>
  </template>
  <style>
  .gradient{
    background: rgb(13,31,43);
    background: linear-gradient(277deg, rgba(13,31,43,1) 1%, rgba(111,122,129,1) 100%, rgba(255,255,255,1) 100%);
  }
  </style>
  <script>
  import Category from '@/api/Category.js'
  import Department from '@/api/Department.js'
  import User from '@/api/User.js'
  import Complaints from '@/api/Complaints.js'

  import ErrorSnackbar from '@/components/ui/ErrorSnackbar'
  
  export default {
    components: { 
      ErrorSnackbar
    },
    props: {
     complaint: Object,
     method: String,
   },
   watch: { 
    department: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;
  
          this.fields.id = data.id;
        }
      },
    },
    data: () => ({
        safe_password: false,
        dialog: false,
        loading: true,
        success: false,
        password: null,
        error: {
          title: null,
          message: null,
          show: false
        },
        categories: [],
        departments: [],
        users: [],
        fields:{
            files: null,
            title: null,
            department_id: null,
            description: null,
            confidential: true,
            anonymous: false,
            person: {
              name: null,
              email: null,
              telephone: null
            }
        },
    }),
    mounted(){
        this.fillBaseData()
        window.scroll(0, 0)
    },
    methods:{
      changedSwitchesAnonymous(item){
        if(item)
          this.fields.confidential = false
        else
          this.fields.confidential = true
      },
      changedSwitchesConfidential(item){
        if(item)
          this.fields.anonymous = false
        else
          this.fields.anonymous = true
      },
      downloadPassword() {
        let fileType = 'txt'
        let fileName = "password"
        var blob = new Blob([this.password], { type: fileType });

        var a = document.createElement('a');
        a.download = fileName;
        a.href = URL.createObjectURL(blob);
        a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setTimeout(function() { URL.revokeObjectURL(a.href); }, 1500);
      },
      copyToClipboard() {
        // Copy the text inside the text field
        navigator.clipboard.writeText(this.password);
      },
      onSuccess() {
        if(this.fields.id != null) {
          this.success = false
          return
        }
        this.$router.push('/admin/settings/departments');
      },      
      async fillBaseData(){
        this.loading = true

        Category.list().then(({data}) => {
          this.categories = data;
        });

        Department.list().then(({data}) => {
          this.departments = data;
        });

        User.list().then(({data}) => {
          data.forEach(element => {
            this.users.push({
              id: element.id,
              name: element.name,
              initials: element.initials,
              email: element.email,
              selected: true
            })
          });
        });


        this.loading = false
      },
      submit() {

        let selectedUsers = [];
        this.users.forEach(element => {
          if(element.selected)
            selectedUsers.push(element)
        });

        if(selectedUsers.length <= 0){
          this.error.message = "Precisa de pelo menos um utilizador ativo para tratar do seu processo"
          this.error.show = true;
          return;
        }


        this.$refs.form.validate().then((result) => {
          this.loading = true;
          
          if(!result){
            this.loading = false;
            return;
          }
  

          const formData = new FormData();
          formData.append('anonymous', this.fields.anonymous)
          formData.append('category_id', this.fields.category_id)
          formData.append('department_id', this.fields.department_id)
          formData.append('confidential', this.fields.confidential)
          
          selectedUsers.forEach(element => {
            formData.append('users[]', element.id)            
          });
          
          formData.append('description', this.fields.description)
          if(this.fields.files != null){
            for (var i = 0; i < this.fields.files.length; i++ ){
                let file = this.fields.files[i];
                formData.append('files[]', file);
            }
          }
          formData.append('person', JSON.stringify(this.fields.person))
          formData.append('title', this.fields.title)
  
          Complaints[this.method](formData, this.fields.id).then((response) => {
  
            this.success = true;
  
            this.loading = false;

            this.password = response.data.password
            this.dialog = true
            
          }).catch(err => {
            this.loading = false;
  
              if(err.response.status == 422) {
  
                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
              }
  
              this.error.title = "Erro " + err.response.status;
  
              this.error.message = err.response.data.message;
  
            });
        })       
      }
    }
  };
  </script>
  